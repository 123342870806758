import {
  downloadXml,
  downloadFile,
  getFilenameFromContentDisposition,
  getSignedDocument,
} from '@/helpers/documents'

const actions = [
  {
    icon: "mdi-file-pdf-box",
    action: "downloadPdf",
    name:"Скачать PDF"
  },
  {
    icon:"mdi-content-copy",
    name:"Копировать документ",
    action:"copyDocument",
    divider:true,
    component:() => import('@/components/transit/journal/copy-document.vue')
  },
  {
    icon:"mdi-tray-arrow-down",
    name:"Скачать xml",
    action:"downloadXml"
  },
  {
    icon:"mdi-delete",
    name:"Удалить документ",
    action:"deleteDocument",
  },
  {
    icon:"mdi-account",
    name:"Изменить исполнителя",
    action:"changePerformer",
    divider:true,
    component:() => import('@/components/documents/change-performer.vue')
  },
  null,
  {
    icon: "mdi-restore",
    action: "revokeDocument",
    name:"Отозвать документ"
  },

]

const methods = {
  getActionIndex(actionId){
    const action = this.actions.find(i => i?.action === actionId)
    return this.actions.indexOf(action)
  },
  getRevokeXml(eclientRequestId){
    return this.$store.dispatch('transit/getRevokeXml', {eclientRequestId})
      .then((res) => res.data)
  },
  revokeDeclaration({xml, guid, declarationId}){
    return this.$store.dispatch('transit/revokeDeclaration', {xml, guid, declarationId})
      .then((res) => {
        this.$success('Документ отозван')
        return res
      }).catch((err) => {
        this.$error(err.response.data)
        throw err
      })
  },
  getSignedDocument,
  getSignedDocumentWithGuid({xml, guid}){
    return new Promise((resolve, reject) => {
      this.getSignedDocument({xml, guid}).then((signedXml) => {
        return resolve({xml: signedXml, guid})
      }).catch((err) => reject(err))
    })
  },
  getEclientRequestId(item){
    return new Promise((resolve, reject) => {
      if(!item?.eclient_request_id) return reject("Отсутствует E_CLIENT_REQUEST_ID")
      return resolve(item?.eclient_request_id)
    }).catch(err => {
      this.$error(err)
      throw err
    })
  },
  revokeDocument({id,item}){
    this.getEclientRequestId(item)
      .then(this.getRevokeXml)
      .then(this.getSignedDocumentWithGuid)
      .then(({xml, guid}) => Promise.resolve({
        guid, xml, declarationId: id
      }))
      .then(this.revokeDeclaration)
      .catch(() => {})
  },
  downloadXml({id}){
    this.$store.dispatch("transit/getXml", id).then((res) => {
      downloadXml(res.data, getFilenameFromContentDisposition(res))
    });
  },
  downloadPdf({id}){
    this.$store.dispatch('transit/downloadPdf', id).then((res) => {
      const name = getFilenameFromContentDisposition(res)
      downloadFile({data:res.data, type:'application/pdf', name})
    }).catch(() => this.$error())
  },
  copyDocument({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  deleteDocument({id, item}){
    const value = !item.is_trash
    this.$store.dispatch('transit/toTrash',{id, value} ).then(() => {
      this.$success("Документ удален")
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  },
  changePerformer({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
}

export {
  methods, actions
}
